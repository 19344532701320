import { Box, Div, Img, Text, Title } from 'components';
import { RequestDemo, TryOrSchedule } from 'containers/fr';
import ComfortCottage from "images/ComfortCottage8bitcopy.png";
import Layout from 'layouts/en';
import React from 'react';
import meetDesktop from '../images/meet-desktop-fr.png';

const StartOrDemo = ({}) => {
  return (
    <Div position={'relative'}>
      <Div display={['none','block']} position={'absolute'} top={180} right={65}>
        <Img shadow src={ComfortCottage} width={335} alt={''} />
      </Div>
      <Div width={['100%',776]}>
        <Div px={[0]} py={[7,8]}>
          <Title mb={[4,5]} fontSize={[7,8]}>Il est temps d’améliorer votre qualité de vie </Title>
          <Text mb={[7,8]} fontSize={[5,8]}>Les utilisateurs de Stairlin ont plus de temps pour leurs loisirs et leurs familles</Text>
        </Div>
        <Div display={'flex'}>
        <Div width={['100%',314]} mr={6} display={['none','block']}>
            <Title mb={6} fontSize={[5]}>Démarrez maintenant</Title>
            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                <li><Text fontSize={3} mb={3}>• Installation simple</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de frais initiaux</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de carte de crédit requise</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de frais de résiliation</Text></li>
            </ul>
        </Div>
          <Div mx={[0, 6]} width={['100%',314]}>
            <RequestDemo />
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['left']}>
        <Title mb={5} fontSize={[7,9]}>Téléconsultation</Title>
        <Text tag={'h2'} mb={7} fontSize={[6,8]}>Nous sommes heureux de vous proposer dès à présent notre nouvelle application vidéo, pour vos rendez-vous virtuels, qui s’intègre dans la panoplie des services de Stairlin.</Text>

        <Div mb={[60, 80]} flex={1}>
          <Box bg={'white'} m={0} p={[4,4]}>
            <img width={['100%']} src={meetDesktop} alt=""/>
          </Box>
        </Div>

        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Nous pensons forts à tous les thérapeutes et autres professionnels qui sont maintenant très touchés par la pandémie. Comme beaucoup d’indépendants, les thérapeutes se tournent de plus en plus vers les solutions vidéos comme alternatives.</Text>
        <Text mb={5} fontSize={[3,5]} fontWeight={700} textAlign={'justify'}>Mais comment choisir, parmi les nombreuses options, une solution fiable et sure qui garantisse la confidentialité du patient ?</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin s'est donné pour mission de vous fournir un outil sécurisé afin que vous puissiez continuer à aider vos patients depuis la sécurité de votre domicile. Notre solution vidéo pour vos rendez-vous virtuels est :</Text>

        <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Fabriqué en Suisse</Text></li>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Conforme aux politiques de protection des données (GDPR) et de santé (HIPAA)</Text></li>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Génère un lien unique et sécurisé pour chaque rendez-vous</Text></li>
        </ul>

        <Div mt={[7,8]}>
          <TryOrSchedule />
        </Div>

        <Div mt={[7,180]}>
          <Text tag={'h2'} mb={5} fontSize={[6,8]} fontWeight={700}>Que fait Stairlin ?</Text>
          <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin est votre solution clé en main pour :</Text>
          <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• La prise de rendez-vous (en ligne ou non)</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Les interactions avec les clients (notifications, sms, Whatsapp, Email)</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• L’automatisation de votre facturation</Text></li>
          </ul>
          <Text mt={60} mb={5} fontSize={[3,5]} textAlign={'justify'}>Nous intégrons tous vos canaux de communication et vos tâches administratives dans une plateforme simple à utiliser pour que vous puissiez :</Text>
          <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Communiquez librement avec vos patients dans un cadre confidentiel</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Gérez votre planning, votre facturation et votre comptabilité en quelques clics</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Consacrez plus de temps à faire ce que vous aimez, à aider les autres</Text></li>
          </ul>
          <Text mt={60} mb={5} fontSize={[3,5]} textAlign={'justify'}>Laissez Stairlin faire le gros du travail pour que vous puissiez rester en contact avec vos clients.</Text>
        </Div>

        <Div mt={[7,8]}>
          <StartOrDemo />
        </Div>
      </Div>
    </Layout>
  )
}
